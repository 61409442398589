// Dependencies
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import VeeValidate from 'vee-validate';
import VueClipboard from 'vue-clipboard2'


VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(VueCookie);

// for validation
Vue.use(VeeValidate);

//Heap Analytics
Vue.prototype.heap = window.heap;

// Our our styles and our overrides
import './assets/styles/main.scss'

// Our application
import App from './App.vue'
import router_factory from './router'
import store from './store'
// import './registerServiceWorker'
import api from './api.js'
import moment from 'moment';

//Vue Tour V3
import VueTour from 'vue-tour'
require('./assets/styles/vue-tour.css')
Vue.use(VueTour)

// Toast for notifications
/*
type :
success
info
warning
error
default
*/
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, {
    position: 'top',
    duration: 5000,
    dismissible: true,
    type: 'success',
});

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

Vue.filter('formatDateShort', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM')
    }
});

Vue.filter('formatDateLong', function (value) {
    if (value) {
        return moment(String(value)).format('dddd, MMMM Do YYYY')
    }
});

Vue.filter('formatDatetime', function (value) {
    if (value) {
        return moment(String(value)).format('MMMM Do YYYY, hh:mm A')
    }
});


window.api = api


// Only execute the app if the app element is there
if (document.getElementById('app') !== null) {

    // Router
    const router = router_factory()

    // Setup app
    window.app = new Vue({
        router: router,
        store,
        render: h => h(App)
    }).$mount('#app')

    // Configure axios to add csrf token
    axios.defaults.transformRequest.push(function (data, headers) {
        headers['X-CSRF-Token'] = window.app.$cookie.get('csrf_token');
        return data;
    })

    api.interceptors.request.use((config) => {
        const share_organisation = window.app.$store.state.share_organisation
        if (
            (config.url.startsWith("integrations/authed-xero") || config.url == "@@session-info") &&
            share_organisation.xero_authed_organisation_id
        ) {
            config.headers['Xero-Share-Org'] = share_organisation.xero_authed_organisation_id;
            config.headers['Xero-Share-Org-Token'] = share_organisation.share_secret;
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    });

}
Vue.config.devtools = true
