<template>
  <div>
    <template v-if="!signed_up">
      <p>
        <label>Email:</label>
        <input v-model="email" type="email" class="form-control">
      </p>
      <p class="error_msg" v-if="email_error">{{ email_error }}</p>

      <p>
        <label>Password:</label>
        <input v-model="password" type="password" class="form-control">
      </p>
      <p class="error_msg" v-if="password_length_check">
        Password must be at least 12 characters
      </p>
      <p class="error_msg" v-else-if="password_error">{{ password_error }}</p>

      <p>
        <label>Repeat Password:</label>
        <input v-model="password2" type="password" class="form-control">
      </p>
      <p class="error_msg" v-if="password2_error">{{ password2_error }}</p>

      <button
        class="btn btn-primary btn-block"
        @click="check_signup_inputs"
        :disabled="loading"
      >
        <span class="ml-3">
          <strong>{{ button_text }}</strong>
        </span>
      </button>

      <a href="#" @click="exit">
        <p>Use a different sign up method</p>
      </a>
    </template>
    <template v-else>
      <p>
        <div class="row g-0 align-items-end">
          <div class="col">
            <label>Email Verification Code</label>
            <input v-model="otp" name="otp" type="text" class="form-control">
          </div>
          <button
            @click="send_email_otp"
            :loading="otp_sending"
            class="btn btn-sm btn-primary mx-2 py-2 col-4">
            Send Code
          </button>
        </div>
      </p>
      <p v-if="otp_sent" class="alert alert-success">
        <strong>Sent.</strong> Check your inbox.
      </p>
      <p class="error_msg" v-if="otp_error">
        {{ otp_error }}
      </p>
      <button
        class="btn btn-primary btn-block"
        @click="login"
        :disabled="login_loading"
      >
        <span class="ml-3">
          <strong>Continue</strong>
        </span>
      </button>

    </template>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  name: "emailLogin",
  data() {
    return {
      loading: false,
      email: "",
      email_error: null,
      password: "",
      password_error: null,
      password2: "",
      password2_error: null,
      signed_up: false,
      login_loading: false,
      otp: null,
      otp_sending: false,
      otp_error: null,
      otp_sent: false,
    };
  },
  computed: {
    button_text(){
      if (this.loading) {
        return "Loading..."
      }
      return "Sign Up"
    },
    password_length_check() {
      return (this.password.length > 0 && this.password.length < 12)
    }
  },
  methods: {
    check_signup_inputs() {
      // Clear error messages
      this.email_error = null;
      this.password_error = null;
      this.password2_error = null;

      // Check inputs
      if (this.email == "") {
        this.email_error = "This field is required"
      } else if (this.password == "") {
        this.password_error = "This field is required"
      } else if (this.password.length < 12) {
        this.password_error = "Password must be at least 12 characters"
      } else if (this.password2 != this.password) {
        this.password2_error = "Passwords must match"
      } else {
        this.signup()
      }
    },
    signup() {
      // Make signup api request
      this.loading = true
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'signup',
          app_action_category: 'signup',
          app_action_label: 'password',
      })

      api.post('@@signup', {email: this.email, password: this.password})
      .then(resp => {
        this.signed_up = true;
      })
      .catch(error => {
        // Communicate reason sign up failed
        if (error.response.data.message == "Missing email field") {
          this.email_error = "This field is required"
        } else if (error.response.data.message == "Invalid email") {
          this.email_error = "Please enter a valid email"
        } else if (error.response.data.message == "Email already in use") {
          this.email_error = "Email already in use"
        } else if (error.response.data.message) {
          // Remaining errors are password complexity errors
          this.password_error = error.response.data.message
        } else {
          // In case of 500 error/network issue
          this.password_error = "Sign up failed"
        }
      })
      .then(() => this.loading = false)
    },
    exit() {
      this.$emit("exit")
    },
    async send_email_otp() {
      // Reset state if the button was pressed a second time
      this.otp_error = null;
      this.otp_sent = false;

      // Make async request
      this.otp_sending = true;
      let response = null;
      let error = null;
      try {
        const authorization = "Basic " + btoa(this.email + ":" + this.password);
        response = await api.post(
          '@@send-email-otp',
          {},
          { headers: { authorization } }
        );
      } catch(err) {
        error = err;
        if (error.response) {
          response = error.response;
        }
      }
      this.otp_sending = false;

      // Process result
      if (response !== null) {
        if (response.status === 200) {
          this.otp_sent = true;
        } else if (response.status === 403) {
          this.otp_error = "Invalid username or password"
        } else {
          this.otp_error = "Unexpected error";
        }
      } else {
        this.otp_error = error | "Unexpected error"
      }
    },
    async login() {
      // Attempt to login
      this.login_loading = true
      this.otp_error = null
      let response = null
      let error = null
      try {
        response = await api.post(
          '@@login',
          {otp: this.otp},
          {headers: {
            'Authorization': "Basic " + btoa(this.email + ":" + this.password)
          }}
        )
      } catch(err){
        error = err;
        if (error.response) {
          response = error.response;
        }
      }
      this.login_loading = false;

      // Process result
      if (response !== null) {
        if (response.status === 200) {
          document.location = "/"
        } else if (response.status === 403) {
          this.otp_error = "Invalid email verification code"
        } else {
          this.otp_error = "Unexpected error";
        }
      } else {
        this.otp_error = error | "Unexpected error"
      }
    },
  },
};
</script>
<style type="text/css" scoped>
.error_msg {
  color: #000;
  background-color: #FFBABA;
  border: 1px solid;
  padding: 15px;
}
</style>
