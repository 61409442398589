<template>
  <div>
    <div class="app-main">
      <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <router-link to="/" class="navbar-brand brand-logo mr-5">
            <img src="@/assets/images/PayPredict_Logo_white.png" class="mr-2" alt="logo" />
          </router-link>
          <!-- <div class="navbar-brand brand-logo mr-5">
            <img src="@/assets/images/PayPredict_Logo_white.png" class="mr-2" alt="logo" />
          </div> -->
        </div>
      
        <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        </div>
      </nav>

      <div class="container-fluid page-body-wrapper">
        <div class="main-panel">
          <div class="content-wrapper">

            <div class="row justify-content-md-center">
              <div class="col-sm-12 col-sm-12 col-md-6 col-lg-6 d-flex align-self-stretch">
                <div class="card w-100">
                  <div class="card-body">
                    <PageTitle title="Don't have your Xero details handy?" />

                    <p class="mb-3">
                      Enter your email address below and we'll send you a link for later, when you have your details. 
                    </p>

                    <p class="mb-3">
                      We'll even include an overview of PayPredict in case you want to forward the email on to your accountant or bookkeeper. 
                    </p>

                    <form @submit.prevent.stop="save()" class="validate-form" novalidate>
                      <!-- individual -->
                      <template>
                        <div class="row">
                          <label for="Email" class="col-12 col-form-label">
                            EMAIL
                            <span style="color: red">*</span>
                          </label>
                          <div class="col-sm-12">
                            <input type="text" 
                            class="form-control" 
                            id="Email" 
                            v-model="form.email"
                            name="email" 
                            data-vv-as="Email"
                            v-validate="'required|email'">
                            <span class="invalid-feedback" v-if="errors.has('email')">
                              {{ errors.first('email') }}
                            </span>
                          </div>
                        </div>
                      </template>

                      <div class="my-4">
                        <VueRecaptcha :sitekey="siteKey" :loadRecaptchaScript="true" />
                      </div>

                    
                      <!-- save button -->
                      <div class="row mt-2 justify-content-start">
                        <div class="col">
                          <button
                            :disabled="errors.any()"
                            type="submit"
                            class="btn btn-primary col-sm-12 col-md-3"
                          >Save</button>
                        </div>
                        </div>
                    </form>
                    
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import api from "@/api.js";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'RequestAccess',
  components: {
    PageTitle,
    VueRecaptcha
  },
  data() {
    return {
      siteKey: '6LdJwR8aAAAAAGHwcSb6jbUS9lrQ8JOdDfXrSF_q',
      form: {},
      showSuccessMessage: false,
    }
  },
  methods: {
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          api.post(
            '@@update-xero-customer-abn',
            {
              ...this.form,
            },
            {headers: {'Xero-customer-identity': this.token}},
          ).then(() => {
            this.$toast.open({ message: "Your email is on its way!"})
          })
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  }
}
</script>
