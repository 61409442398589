<template>
  <div>
    <div
      v-if="
        xero_is_connected ||
        share_organisation_id != null ||
        xero_authed_organisation_id === '24b59e28-24e9-422a-9e1e-82120c31957c'
      "
      class="app-main"
    >
      <slot></slot>
    </div>
    <div v-else>
      <div class="app-main">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div
            class="content-wrapper login-wrapper-error d-flex align-items-center auth px-0"
          >
            <div class="row w-100 mx-0">
              <div class="col-lg-4 mx-auto">
                <div
                  class="auth-form-light auth-form-shadow text-left py-5 px-4 px-sm-5"
                >
                  <div class="brand-logo">
                    <img src="@/assets/images/PayPredict_Logo.png" alt="logo" />
                  </div>
                  <h4>Oh no!</h4>
                  <h6 class="pt-3">You have been disconnected from Xero.</h6>
                  <div class="pt-3">
                    <button
                      class="btn btn-primary btn-block mt-4 mb-4"
                      @click="GoToLogin()"
                      id="btnConnectWithXeroIdle"
                    >
                      <img
                        src="@/assets/img/xero-logo.png"
                        style="width: 40px"
                      />
                      <span class="ml-3">
                        <strong>Connect with Xero</strong>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- content-wrapper ends -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "appmain",
  computed: {
    ...mapGetters(["xero_is_connected"]),
    xero_authed_organisation_id() {
      return this.$store.state.session_info.xero_authed_organisation_id;
    },
    share_organisation_id() {
      return this.$store.state.share_organisation.xero_authed_organisation_id;
    },
  },

  methods: {
    GoToLogin() {
      location.assign("/login/xero");
    },
  },
};
</script>

