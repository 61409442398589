<template>
  <div id="app">
    <div class="container-scroller">
      <!-- IF THERE IS A 500 ERROR SHOW THIS -->
      <div id="application-fault" v-if="application_fault || test.logout">

        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div
            class="content-wrapper login-wrapper d-flex align-items-center auth px-0"
          >
            <div class="row w-100 mx-0">
              <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5  mx-auto">
                <div
                  class="card text-left py-5 px-4 px-sm-5"
                >
                  <div class="brand-logo">
                    <img
                      src="@/assets/images/PayPredict_Logo.png"
                      alt="PayPredict"
                    />
                  </div>
                  <p>
                    You have been idle for too long, to continue, please
                    login again.
                  </p>
                  <PasswordReset v-if="password_reset" />
                  <div v-else>
                    <div class="pt-3 d-grid">
                      <button
                        class="btn btn-secondary"
                        @click="refreshPage"
                        style="height: 50px;"
                        id="btnRefreshOnAppFault"
                      >
                        <span class="ml-3">
                          Try Refreshing the Page
                        </span>
                      </button>
                      <!--
                        Login:
                        They are an accoutant or bookkeeper
                      -->
                      <Login
                        v-if="$store.state.signup_type === 'advisor'"
                      />
                      <!--
                        Login:
                        They are a normal business with or without a refer ID
                      -->
                      <ReferLogin v-else />
                  </div>
                  </div>
                </div>
                <div class="mt-5 bg-grey px-1 py-2">
                  <p class="text-muted mt-3 subtitle2">
                    <strong>Problems or Questions?</strong> Drop us an
                    email at
                    <a href="mailto: team@paypredict.com.au"
                      > team@paypredict.com.au</a
                    >
                    and we'll get back to you straight away.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- content-wrapper ends -->
        </div>
      </div>
      <!-- IF THERE IS A 500 ERROR SHOW THIS -->

      <!-- No error, continue with the app -->
      <div v-else>
        <template>
          <!-- Load data - show the spash screen -->
          <RequestAccess v-if="is_request_access"/>

          <CustomerUpdateABN v-else-if="is_customer_abn_url"/>
          <Splash v-else-if="session_dirty" />
          <template v-else>
            <!--
              ##################################################################################
              Login:
            -->
            <div v-if="!logged_in && share_organisation_id == null">
              <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div
                  class="content-wrapper login-wrapper d-flex align-items-center auth px-0"
                >
                  <div class="row w-100 mx-0">
                    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5  mx-auto">
                      <div
                        class="card text-left py-5 px-4 px-sm-5"
                      >
                        <div class="brand-logo">
                          <img
                            src="@/assets/images/PayPredict_Logo.png"
                            alt="PayPredict"
                          />
                        </div>
                        <PasswordReset v-if="password_reset" />
                        <div v-else>
                          <div class="pt-1">
                            <!--
                              Login:
                              They are an accoutant or bookkeeper
                            -->
                            <Login
                              v-if="$store.state.signup_type === 'advisor'"
                            />
                            <!--
                              Login:
                              They are a normal business with or without a refer ID
                            -->
                            <ReferLogin v-else />
                        </div>
                        </div>
                      </div>
                      <div class="mt-5 bg-grey px-3 py-2">
                        <p class="text-muted mt-3 subtitle2">
                          <strong>Problems or Questions?</strong> Drop us an
                          email at
                          <a href="mailto: team@paypredict.com.au"
                            > team@paypredict.com.au</a
                          >
                          and we'll get back to you straight away.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- content-wrapper ends -->
              </div>
            </div>

            <!--
              ##################################################################################
              Signup:
              They have authed with Xero and we need their name and email address
            -->
            <div v-else-if="crisko_user_setup_incomplete && share_organisation_id == null">
              <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div
                  class="content-wrapper login-wrapper-2 d-flex align-items-center auth px-0"
                >
                  <div class="row w-100 mx-0">
                    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5  mx-auto">
                      <div
                        class="card text-left py-5 px-4 px-sm-5"
                      >
                        <div class="card-body">
                          <div class="brand-logo">
                            <img
                              src="@/assets/images/PayPredict_Logo.png"
                              alt="PayPredict"
                            />
                          </div>
                          <h2>Sign up / Your details</h2>
                          <p>
                            Your name and email matter. <br>
                          </p>
                          <div class="pt-3">
                            <EmailSettings v-bind:submit_action="'add'" />
                          </div>
                        </div>
                        <div class="mt-5 bg-grey px-3 py-2">
                          <p>
                            Your name and email matter, make sure we know who you
                            are and how we can alert you of any customer payment
                            related issues that arise.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- content-wrapper ends -->
              </div>
            </div>
            <!-- They have logged in -->
            <template v-else>
              <!-- If user hasn't selected an org then show the org selector -->

              <div v-if="!is_xero_org_in_url">
               <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div
                  class="content-wrapper login-wrapper-2 d-flex align-items-center auth px-0"
                >
                  <div class="row w-100 mx-0">
                    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5  mx-auto">
                      <div
                        class="card text-left py-5 px-4 px-sm-5"
                      >
                        <div class="card-body d-grid gap-2">
                          <div class="brand-logo">
                            <img
                              src="@/assets/images/PayPredict_Logo.png"
                              alt="PayPredict"
                            />
                            </div>
                            <OrgSelector  />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- If the organisation hasn't loaded wait before showing the dashboard/other views -->
              <StandBy v-else-if="!org_loading_complete && share_organisation_id == null" />
              <AppMain v-else>
                <!-- HEADER if in app -->
                    <Header
                      v-if="share_organisation_id == null"
                      @generatePDF="generatePDF"
                      @printPage="printPage"
                      />

                    <HeaderShare v-else />
                    <div class="container-fluid page-body-wrapper" ref="Main">

                      <!-- / HEADER -->
                      <router-view :share_organisation_id="share_organisation_id" />

                    </div>
                    <!-- FOOTER -->
                    <Footer />
                    <!-- / FOOTER -->

              </AppMain>
            </template>
          </template>
        </template>
      </div>
    </div>
    <!-- container-scroller ends -->
  </div>
</template>

<script>
import "bootstrap";
import Splash from "./views/Splash.vue"; // Loading data screen
import Login from "./views/Login.vue"; // Login for Accountants and Bookkeepers
import ReferLogin from "./views/ReferLogin.vue"; // Login for Business, checks for refer id.
import EmailSettings from "./views/EmailSettings.vue"; // Name and email Settings
import AppMain from "./components/AppMain.vue"; // The main app screen, holds router
import OrgSelector from "./views/OrgSelector.vue"; // The main app screen, holds router
import StandBy from "./views/StandBy.vue";
import PasswordReset from "./views/PasswordReset.vue";
import CustomerUpdateABN from "./views/CustomerUpdateABN.vue";
import RequestAccess from "./views/RequestAccess.vue";

import html2canvas from "html2canvas"

//import Steps from "./components/Steps.vue";

import Header from "./components/layout/Header.vue";
import HeaderShare from "./components/layout/HeaderShare.vue";
import Footer from "./components/layout/Footer.vue";

export default {
  components: {
    Splash,
    Login,
    ReferLogin,
    EmailSettings,
    AppMain,
    StandBy,
    PasswordReset,
    OrgSelector,
    //  Steps,
    Header,
    HeaderShare,
    Footer,
    CustomerUpdateABN,
    RequestAccess,

  },

  data() {
    return {
      waiting_sync_failures: 0,
      waiting_sync_last_failure: null,
      settings: {
        referral_client_id: null,
      },
      test: {
        logout: false,
      },
      password_reset: false,
    };
  },

  methods: {
    GoToLogin() {
      location.assign("/login/xero");
    },
    async printPage() {
      const el = this.$refs.Main;
      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "evenly-paypredict.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
    generatePDF () {
      window.console.log("export!");
      this.$refs.html2Pdf.generatePdf();
    },
    refreshPage() {
      location.reload();
    }
  },

  computed: {
    application_fault() {
      if (this.$store.state.application_fault) {
        window.console.log("ERROR: " + this.$store.state.application_fault);
      }
      return this.$store.state.application_fault;
    },

    session_dirty() {
      return this.$store.state.session_dirty;
    },
    is_customer_abn_url() {
      return window.location.hash.substr(1).startsWith('/customer-update-abn');
    },
    is_request_access() {
      return false
      // window.location.hash.substr(1).startsWith('/request-email');
    },
    share_organisation_id() {
      //console.log(this.$store.state.share_organisation.xero_authed_organisation_id);
      return this.$store.state.share_organisation.xero_authed_organisation_id;
    },

    logged_in() {
      return this.$store.getters.logged_in;
    },

    is_xero_org_in_url() {
      const params = new URLSearchParams(window.location.search)
      return params.get('orgId') != null
    },
    crisko_user_setup_incomplete() {
      return this.$store.getters.crisko_user_setup_incomplete;
    },
    org_loading_complete() {
      return this.$store.getters.org_loading_complete;
    },
    xero_is_connected() {
      return this.$store.getters.xero_is_connected;
    },
    finished_syncing() {
      return this.$store.getters.finished_syncing;
    },

    contacts_report_dirty() {
      return this.$store.state.contacts_report_dirty;
    },

    current_report_id() {
      return this.$store.state.authed_xero.current_report_id;
    },

    fullPath() {
      return this.$route.fullPath
    },
  },

  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || " - PayPredict";
        if (from && to.fullPath !== from.fullPath) {
            window.dataLayer.push({
                event: 'app-page-view',
                app_page_path: to.fullPath,
                app_modal: '',
            })
        }
       window.heap.track('Page View: ' + to.fullPath);
      },
      immediate: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchingSessionData = true;

      this.$store.dispatch("refresh_session").then(({ data }) => {
        if (data.crisko_user_info) {
          this.$store.dispatch("get_users_connected_accounts").finally(() => this.fetchingSessionData = false);
        }
      });
    });

    this.$nextTick(() => {
      // Regularly refresh session so know if Xero no longer authenticated
      window.setInterval(() => {
        this.$store.dispatch("refresh_session");
      }, 60 * 60 * 12 * 1000); // 12 hours
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("orgId") != null && urlParams.get("token") != null) {
      this.$store.dispatch("set_share_organisation");
    } else if (urlParams.get("password_reset_token") != null && urlParams.get("email") != null){
      this.password_reset = true
    }

    window.dataLayer.push({
        event: 'app-page-view',
        app_page_path: '/',
        app_modal: '',
    })
  },
};
</script>

<style lang="scss">
</style>
