var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.xero_is_connected ||
      _vm.share_organisation_id != null ||
      _vm.xero_authed_organisation_id === '24b59e28-24e9-422a-9e1e-82120c31957c'
    )?_c('div',{staticClass:"app-main"},[_vm._t("default")],2):_c('div',[_c('div',{staticClass:"app-main"},[_c('div',{staticClass:"container-fluid page-body-wrapper full-page-wrapper"},[_c('div',{staticClass:"content-wrapper login-wrapper-error d-flex align-items-center auth px-0"},[_c('div',{staticClass:"row w-100 mx-0"},[_c('div',{staticClass:"col-lg-4 mx-auto"},[_c('div',{staticClass:"auth-form-light auth-form-shadow text-left py-5 px-4 px-sm-5"},[_vm._m(0),_c('h4',[_vm._v("Oh no!")]),_c('h6',{staticClass:"pt-3"},[_vm._v("You have been disconnected from Xero.")]),_c('div',{staticClass:"pt-3"},[_c('button',{staticClass:"btn btn-primary btn-block mt-4 mb-4",attrs:{"id":"btnConnectWithXeroIdle"},on:{"click":function($event){return _vm.GoToLogin()}}},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require("@/assets/img/xero-logo.png")}}),_vm._m(1)])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("@/assets/images/PayPredict_Logo.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ml-3"},[_c('strong',[_vm._v("Connect with Xero")])])
}]

export { render, staticRenderFns }